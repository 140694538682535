( function( $ ) {

    $(document).ready( function() {

        if ($('.wp-block-st-loop').length > 0) {

        
        var locations = mapData.locations;

        var svgIcon = window.location.origin + '/wp-content/themes/studiothink/assets/images/map-marker.svg';

        // Inline SVG encoded as a data URL
        var customMarkerIcon = {
            url: svgIcon,  // Use the SVG data URL as the marker icon
            scaledSize: new google.maps.Size(50, 50),  // Scale the icon if necessary
            anchor: new google.maps.Point(25, 50)  // Anchor the marker at the bottom center
        };

        // Map styling
        var mapStyles = [
            {
            "elementType": "geometry",
            "stylers": [
                {
                "color": "#ffffff"
                }
            ]
            },
            {
            "elementType": "labels.icon",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                "color": "#616161"
                }
            ]
            },
            {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                "color": "#f5f5f5"
                }
            ]
            },
            {
            "featureType": "administrative.land_parcel",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                "color": "#bdbdbd"
                }
            ]
            },
            {
            "featureType": "administrative.neighborhood",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                "color": "#eeeeee"
                }
            ]
            },
            {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                "color": "#757575"
                }
            ]
            },
            {
            "featureType": "poi.business",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                "color": "#e5e5e5"
                }
            ]
            },
            {
            "featureType": "poi.park",
            "elementType": "labels.text",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                "color": "#9e9e9e"
                }
            ]
            },
            {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                "color": "#ffffff"
                }
            ]
            },
            {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "road.arterial",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                "color": "#757575"
                }
            ]
            },
            {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                "color": "#dadada"
                }
            ]
            },
            {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                "color": "#48283c"
                }
            ]
            },
            {
            "featureType": "road.highway",
            "elementType": "labels",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                "color": "#616161"
                }
            ]
            },
            {
            "featureType": "road.local",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                "color": "#9e9e9e"
                }
            ]
            },
            {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                "color": "#e5e5e5"
                }
            ]
            },
            {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                "color": "#eeeeee"
                }
            ]
            },
            {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                "color": "#c9c9c9"
                }
            ]
            },
            {
            "featureType": "water",
            "elementType": "labels.text",
            "stylers": [
                {
                "visibility": "off"
                }
            ]
            },
            {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                "color": "#9e9e9e"
                }
            ]
            }
        ];

        // Function to initialize the map
        function initMap() {
            // Center the map on a default location
            var mapCenter = {lat: 53.4027841, lng: -2.9867576};

            // Create the map
            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: 7,
                center: mapCenter,
                styles: mapStyles,
                gestureHandling: "cooperative",
                zoomControl: true, 
                mapTypeControl: false,
                streetViewControl: false, 
                fullscreenControl: false ,
            });

            var markers = [];

            // Autocomplete: Initialize the search box (places input)
            var input = document.getElementById('search-box');
            var autocomplete = new google.maps.places.Autocomplete(input);
            autocomplete.bindTo('bounds', map);

            // Update the map based on the selected location from autocomplete
            autocomplete.addListener('place_changed', function() {
                var place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.log("Autocomplete's returned place contains no geometry");
                    return;
                }

                // If the place has geometry, center the map on the place
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                    map.setZoom(12);  // Set a higher zoom level after the place is found
                }
            });

            // Add standard markers for each location
            locations.forEach(function(location) {
                var marker = new google.maps.Marker({
                    position: {lat: location.lat, lng: location.lng},
                    map: map,
                    title: location.name,
                    icon: {
                        url: svgIcon,
                        scaledSize: new google.maps.Size(50, 50),  // Adjust size
                        anchor: new google.maps.Point(25, 50)  // Anchor point for the marker
                    }
                });
                markers.push(marker);
            });

            // Add marker clusterer to manage the markers
            new markerClusterer.MarkerClusterer({
                map,
                markers: markers,
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'  // Path to cluster icons
            });
        }

        initMap();




        // END MAP



            const postType = 'location';

            //  Init Select 2
            $('.select2-filter').select2({
                minimumResultsForSearch: -1,
                dropdownAutoWidth: true,
                multiple: false,
                theme: "flat",
            }).promise(function() {
                
            });

            

            function initCityFilter() {
                $('.select2-filter[data-taxonomy="city"]').select2({
                    minimumResultsForSearch: Infinity,
                    dropdownAutoWidth: true,
                    theme: "flat",
                    placeholder: $(this).data("placeholder"),
                    ajax: {
                    type: 'POST',
                    url: "/wp-admin/admin-ajax.php",
                    dataType: 'json',
                    data: function() {
                        let s2DataArgs = {
                        action: 'subcat_filter',
                        province: $('.select2-filter[data-taxonomy="province"]').val(),
                        all: $(this).data("all")
                        }
                        
            
            
                        return s2DataArgs;
                    },
                    }
                });
            }
            initCityFilter();

            //  Filter logic for project feed
            //    Preserve current queries
            let url = new URL(window.location);

            let province = $('.select2-filter[data-taxonomy="province"]').val();
            if (province === 'all' || province === null) {
                url.searchParams.delete('province'); //remove filter
            } else {
                url.searchParams.set('province', province); //set new value
            }
            

            //    Update posts
            let loopAjax = function() {
                let dataArgs = {
                    action: 'filter_loop',
                    post_type: postType,
                }
                let ary = url.searchParams.toString().split('&');
                for (let i = 0; i <= ary.length - 1; i++) {
                    let key = ary[i].split('=')[0];
                    let val = ary[i].split('=')[1];
                    dataArgs[key] = val;
                }

                $.ajax({
                    type: 'POST',
                    url: '/wp-admin/admin-ajax.php',
                    dataType: 'html',
                    data: dataArgs,
                    beforeSend: function () {
                        $('.loop-inner-grid').animate({'opacity': 0, 'transform': 'translateY(50px)'}, 300);
                        $('.load-more').fadeOut(300);
                    },
                    success: function (res) {
                        if (res === 'END') {
                            $('.loop-inner-grid').html('<p style="text-align: center;padding: 120px 60px;width: 100%;"><strong>There are no locations matching your search. Please try different filters. We are continuously adding new locations and will hopefully have one in your area soon.</strong></p>');
                            $('.loop-inner-grid').animate({'opacity': 1, 'transform': 'translateY(0)'}, 300);
                        } else {
                            $('.loop-inner-grid').html(res);
                            window.setTimeout(function () {
                                $('.loop-item').addClass('intersected');
                                $('.loop-inner-grid').animate({'opacity': 1, 'transform': 'translateY(0)'}, 300);

                
                                if (parseInt($('#loop-count').data('count')) > parseInt($('.wp-block-st-loop').data('per-page')) && dataArgs.pg !== '2') {
                                    $('.load-more').fadeIn(300);
                                }
                                
                            }, 300);
                        }
                        resetAccordionHeight();
                    }
                })
            }

            // Filter change event
            $('.select2-filter').on('change', function () {
                url.searchParams.set('pg', 1);
                
                let $filters = $('.select2-filter');

                $filters.each(function() {
                    let selection = $(this).val();
                    let taxonomy = $(this).data('taxonomy');
                    if (selection === 'all' || selection === null || selection === '') {
                        url.searchParams.delete(taxonomy); //remove filter
                    } else {
                        url.searchParams.set(taxonomy, selection); //set new value
                    }
                });
                
                console.log($(this).next('.select2').find('.select2-selection__rendered'));
                $(this).next('.select2').find('.select2-selection__rendered').attr('data-taxonomy', $(this).data('placeholder') + ': ');

                if ($(this).data('taxonomy') === 'province') {
                    
                    $('.select2-filter[data-taxonomy="city"]').val(null);
                    initCityFilter();

                    url.searchParams.delete('city');

                    // if (selection === 'all' || selection === null) {
                    //     $('.city-filter').removeClass('show-filter');
                    // } else {
                    //     $('.city-filter').addClass('show-filter');
                    // }
                }

                console.log(url.searchParams.toString());
                loopAjax();

                // gtag(
                //     'event', 'filterUpdate',
                //     {
                //         'province': url.searchParams.get('province'),
                //         'city': url.searchParams.get('city'),
                //         'productCat': url.searchParams.get('product-cat')

                //     }
                // );

                
            });


            $(document).on('click', '.load-more a', function(e) {
                e.preventDefault();

                let dataArgs = {
                    action: 'filter_loop',
                    post_type: 'location',
                  }

                  url.searchParams.set('pg', 2); //set new value

                  let $filters = $('.select2-filter');

                    $filters.each(function() {
                        let selection = $(this).val();
                        let taxonomy = $(this).data('taxonomy');
                        if (selection === 'all' || selection === null || selection === '') {
                            url.searchParams.delete(taxonomy); //remove filter
                        } else {
                            url.searchParams.set(taxonomy, selection); //set new value
                        }
                    });

                  let ary = url.searchParams.toString().split('&');

                  console.log(ary);
                    for (let i = 0; i <= ary.length - 1; i++) {
                        let key = ary[i].split('=')[0];
                        let val = ary[i].split('=')[1];
                        dataArgs[key] = val;
                    }

                $.ajax({
                    type: 'POST',
                    url: '/wp-admin/admin-ajax.php',
                    dataType: 'html',
                    data: dataArgs,
                    beforeSend: function (data) {
                        $('.loop-pagination .load-more').addClass('loading');
                    },
                    success: function (res) {
                        $('.loop-pagination .load-more').removeClass('loading');
                    
                        if (res === 'END') return;
                        $('.wp-block-st-loop .loop-inner-grid').append(res);
                        window.setTimeout(function () {
                            $('.loop-item').addClass('intersected');
                            resetAccordionHeight();
                        }, 600);
  
                    }
                });
                $('.load-more').fadeOut(300);

            });

            function resetAccordionHeight() {
                $('.accordion-body-wrapper').each(function() {
                    let height = $(this).children('.accordion-body-inner').outerHeight() + 1 + 'px';
                    $(this).css('--body-height', height);
                });
            }
            
            resetAccordionHeight();

            $(window).on('resize', function() {
                resetAccordionHeight();
            });
    
    
            $(document).on('click', '.accordion-title', function(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                if($(this).parent().hasClass('open')) {
                    $(this).parent().removeClass('open');
                } else {
                    $('.accordion-item').removeClass('open');
                    $(this).parent().addClass('open');
                }

                // if ($(this).parent().hasClass('open')) {
                //     // gtag(
                //     //     'event', 'openedLocation',
                //     //     {
                //     //         'location': $(this).find('.accordion-heading').text()
    
                //     //     }
                //     // );
    
                //     console.log($(this).find('.accordion-heading').text())
                // }
            });

            $('.select2-filter').each(function() {
                if($(this).next('.select2').find('.select2-selection__rendered').find('.select2-selection__placeholder').length === 0) {
                    $(this).next('.select2').find('.select2-selection__rendered').attr('data-taxonomy', $(this).data('placeholder') + ': ');
                }

                
            });

        }

    } );

} )( jQuery );
